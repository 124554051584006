import React from 'react';
import { Row, Col, Card } from 'antd';

const features = [
    {
        title: 'Welcome to our Preowned/Used Car Website - Your Ultimate Destination for Quality and Affordable Preowned Vehicles',
        description: 'Looking for a reliable and affordable preowned car? Look no further! Our Preowned/Used Car Website is your one-stop destination for finding the perfect preowned vehicle that fits your budget and lifestyle. With our extensive inventory, competitive prices, and exceptional customer service, we are committed to making your preowned car shopping experience convenient, transparent, and satisfying.',
    },
    {
        title: 'Find a Wide Selection of Quality Preowned Vehicles',
        description: 'At our Preowned/Used Car Website, we offer a diverse range of preowned vehicles to suit every need and preference. Whether youre searching for a compact sedan, a spacious SUV, a versatile crossover, our inventory has got you covered. Each vehicle in our selection undergoes a thorough inspection to ensure its quality and reliability, so you can have peace of mind knowing that youre getting a dependable preowned car.',
    },
    {
        title: 'Unbeatable Value for Your Money',
        description: 'We understand that value for money is essential when purchasing a preowned car. Thats why we strive to provide competitive prices that make owning a quality preowned vehicle affordable for everyone. Our pricing is transparent and upfront, with no hidden fees or surprises. We also offer flexible financing options to fit your budget, allowing you to drive away in your dream car without breaking the bank.',
    },
    {
        title: 'Comprehensive Vehicle Information for Informed Decisions',
        description: 'We believe in empowering our customers with the information they need to make informed decisions. Each preowned car listing on our website comes with comprehensive details, including specifications, mileage, ownership history, service records, and even Carfax reports. We want you to have complete transparency about the vehicles condition and background, so you can choose the preowned car that best meets your requirements.',
    },
    {
        title: 'Simple and Convenient Online Shopping Experience',
        description: 'We understand that your time is valuable, and thats why weve designed our Preowned/Used Car Website to make your car shopping experience easy and convenient. Our user-friendly interface allows you to browse our inventory, filter search results based on your preferences, and explore detailed vehicle listings from the comfort of your own home. You can also schedule test drives, request additional information, and even apply for financing directly through our website.',
    },
    {
        title: 'Exceptional Customer Service Every Step of the Way',
        description: 'At our Preowned/Used Car Website, we prioritize providing exceptional customer service. Our team of knowledgeable and friendly professionals is here to assist you at every step of the car-buying process. Whether you have questions about a specific vehicle, need guidance on financing options, or require assistance with paperwork, we are dedicated to ensuring your complete satisfaction and making your preowned car purchase hassle-free.',
    },
    {
        title: 'Start Your Preowned Car Journey Today!',
        description: 'Ready to find the perfect preowned vehicle? Explore our Preowned/Used Car Website today and discover a wide selection of quality vehicles at unbeatable prices. With our transparent pricing, comprehensive vehicle information, and exceptional customer service, we are confident that you will find the preowned car that exceeds your expectations. Begin your preowned car journey with us and experience the joy of driving a reliable and affordable vehicle.',
    },
    {
        title: 'Jolly Motors - Your Trusted Destination for Quality Used Cars!',
        description: "Welcome to Jolly Motors, where your dream of owning a reliable and affordable used car becomes a reality! We take pride in being your trusted destination for finding top-quality pre-owned vehicles that fit your budget and lifestyle. Whether you're a first-time buyer or a seasoned car enthusiast, our vast selection of well-maintained used cars is sure to have something just for you.",
    },
];

const DreamPreowned = () => {
    return (
        <div style={{ padding: '30px' }}>
            <h2>Discover Your Dream Preowned Car Today!</h2>
            <p>Explore our inventory, schedule a test drive, and take the first step towards owning a quality preowned vehicle. Our Preowned Car Home Page is designed to provide you with all the information you need to make an informed decision. Start your car-buying journey with us and experience the satisfaction of finding your perfect preowned car.</p>
            <Row gutter={[16, 16]}>
                {features.map((feature, index) => (
                    <Col xs={24} sm={24} md={12} key={index}>
                        <Card
                            style={{
                                textAlign: 'center',
                                height: '100%',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
                            }}
                        >
                            <h5 style={{ marginTop: '15px', marginBottom: '10px' }}>{feature.title}</h5>
                            <p>{feature.description}</p>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default DreamPreowned;
