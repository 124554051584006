import React from 'react'
import GalleryAlbum from '../components/GallaryAlbum'
import FourImageSection from '../components/FourImageSection'

const Gallary = () => {
  return (
    <div>
      <section>
        <img width={'100%'} src="./images/banner-01.jpg" alt="" />
      </section>

      <GalleryAlbum />

      <section>
        <FourImageSection />
      </section>
    </div>
  )
}

export default Gallary
