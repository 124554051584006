import { Col, Row } from 'antd'
import React from 'react'
import ContactForm from '../components/ContactForm'

const Contact = () => {
  return (
    <div>
      <section>
        <img width={'100%'} src="./images/banner-01.jpg" alt="" />
      </section>

      <section>
        {/* LET'S GET IN TOUCH! section */}
      </section>

      <section>
        <Row>
          <Col span={12} >
            <ContactForm />
          </Col>
          <Col span={12} >
            {/* Side Contact Detail */}
          </Col>
        </Row>
      </section>

      <section>
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d612.1760733018001!2d72.9726052!3d22.5847515!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e4fa868f1ee5d%3A0x7ac64b7b58e9230f!2sCARS24%20Network%20-%20Sell%20Car%20in%20Anand!5e1!3m2!1sen!2sin!4v1723607303837!5m2!1sen!2sin"
          width="100%"
          height="600"
          title='Safar Cars'
          style={{ border: '0' }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </section>

      <section>
        {/* IMage Section */}
      </section>
    </div>
  )
}

export default Contact
