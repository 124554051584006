import { EnvironmentOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, Row, Select } from 'antd';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ProductFilter from '../filter/ProductFilter';
import { clearAllFilter } from '../redux/productSlice';
import FourImageSection from '../components/FourImageSection';

const { Option } = Select;

const BuyCar = () => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;

  const filteredData = useSelector((state) => state.product.filteredData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOption, setSortOption] = useState(null);
  // ---------------------------------------------
  const selectedBrand = useSelector((state) => state.brand.selectedBrand)
  // ---------------------------------------------
  function convertToSlug(name) {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, '')
      .trim()
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');
  }

  const onDetailPage = (slug) => {

    const convertSlug = convertToSlug(slug);

    navigate(`/product/${convertSlug}`)
  }

  const handleClearAll = () => {
    dispatch(clearAllFilter(true))
  }

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleSortChange = (value) => {
    setSortOption(value);
  };

  const getSortedData = () => {
    let sortedData = [...filteredData];

    if (searchQuery) {
      sortedData = sortedData.filter(item =>
        item.product_name.toLowerCase().includes(searchQuery) ||
        item.fuel_type.toLowerCase().includes(searchQuery)
      );
    }

    // ---------------------------------------------
    if (selectedBrand) {
      sortedData = sortedData.sort((a, b) => {
        const isBrandA = a.make === selectedBrand;
        const isBrandB = b.make === selectedBrand;

        if (isBrandA && !isBrandB) return -1;
        if (!isBrandA && isBrandB) return 1;
        return 0;
      });
    }
    // ---------------------------------------------

    if (sortOption === 'priceLowToHigh') {
      sortedData = sortedData.sort((a, b) => a.price - b.price);
    } else if (sortOption === 'priceHighToLow') {
      sortedData = sortedData.sort((a, b) => b.price - a.price);
    } else if (sortOption === 'kmDrivenLowToHigh') {
      sortedData = sortedData.sort((a, b) => a.km_driven - b.km_driven);
    } else if (sortOption === 'yearNewToOld') {
      sortedData = sortedData.sort((a, b) => b.manufacturing_year - a.manufacturing_year);
    } else if (sortOption === 'newestFirst') {
      sortedData = sortedData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }

    return sortedData;
  };

  const sortedData = getSortedData();


  return (
    <>
      <div style={{ display: 'flex' }}>

        <Row gutter={16} style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
          <ProductFilter />
        </Row>

        <Row gutter={16} style={{ width: '70%', display: 'flex', flexDirection: 'column' }}>


          <div>

            <Button onClick={handleClearAll} type="primary" style={{ marginBottom: '20px', width: '100px' }}>
              Clear All
            </Button>

            <span style={{ marginLeft: '20px', lineHeight: '32px' }}>
              {sortedData.length} products found
            </span>

            <Input
              placeholder="Search Products"
              value={searchQuery}
              onChange={handleSearch}
              prefix={<SearchOutlined />}
              style={{ width: '200px', marginRight: '20px' }}
            />

            <Select
              placeholder="Sort By"
              value={sortOption}
              onChange={handleSortChange}
              style={{ width: '200px', marginRight: '20px' }}
            >
              <Option value={null}>None</Option>
              <Option value="priceLowToHigh">Price: Low to High</Option>
              <Option value="priceHighToLow">Price: High to Low</Option>
              <Option value="kmDrivenLowToHigh">KM Driven: Low to High</Option>
              <Option value="yearNewToOld">Year: New to Old</Option>
              <Option value="newestFirst">Newest First</Option>
            </Select>

          </div>


          <div style={{ display: 'flex' }}>
            {sortedData && sortedData.map((item, index) => (
              <Col span={8} key={index}>
                <Card
                  hoverable
                  cover={
                    <div style={{ border: '1px solid #f5f5f5' }}>
                      <img
                        alt="product"
                        src={`${API_BASE_URL}/${item.image[0]}`}
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </div>
                  }
                  onClick={() => onDetailPage(item.product_name)}
                >
                  <h5>{item.product_name}</h5>
                  <p>{item.manufacturing_year} | {item.km_driven} km | {item.fuel_type} | {item.transmission}</p>
                  <h5>₹{item.price}</h5>
                  <p><EnvironmentOutlined /> S.G Highway, Ahmedabad</p>
                </Card>
              </Col>
            ))}
          </div>
        </Row>

      </div>
      <section>
        <FourImageSection />
      </section>
    </>
  )
}

export default BuyCar









// const handleSubmit = (e) => {
//   e.preventDefault();

//   if (!isAuthenticated) {
//     navigate('/login', { state: { from: window.location.pathname, formData } });
//   } else {
//     // Form submission logic here
//     console.log('Form submitted:', formData);
//   }
// };