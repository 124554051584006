import { EnvironmentOutlined } from '@ant-design/icons';
import { Card, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const RelatedProduct = ({ brand }) => {
    const API_BASE_URL = process.env.REACT_APP_API_URL;

    const productData = useSelector((state) => state.product.data);
    const navigate = useNavigate();
    const [data, setData] = useState([]);

    function convertToSlug(name) {
        return name
            .toLowerCase()
            .replace(/[^a-z0-9\s-]/g, '')
            .trim()
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-');
    }

    const onDetailPage = (slug) => {
        const convertSlug = convertToSlug(slug);
        navigate(`/product/${convertSlug}`);
    };

    const matchProduct = () => {
        const matchedProducts = productData.filter(product => product.make === brand);
        const latestProducts = matchedProducts.reverse().slice(0, 4);

        if (latestProducts.length > 0) {
            setData(latestProducts);
        } else {
            alert('Product Url is Not Correct');
            navigate("/buycar");
        }
    };

    useEffect(() => {
        if (productData.length > 0 && data.length === 0) {
            matchProduct();
        }
    }, [productData]);

    return (
        <div>
            <Row gutter={16}>
                {data.length > 0 ? (
                    data.map((item, index) => (
                        <Col span={6} key={index}>
                            <Card
                                hoverable
                                cover={
                                    <div style={{ border: '1px solid #f5f5f5' }}>
                                        <img
                                            alt="product"
                                            src={`${API_BASE_URL}/${item.image[0]}`}
                                            style={{ width: '100%', height: 'auto' }}
                                        />
                                    </div>
                                }
                                onClick={() => onDetailPage(item.product_name)}
                            >
                                <h2>{item.product_name}</h2>
                                <p>{item.manufacturing_year} | {item.km_driven} km | {item.fuel_type} | {item.transmission}</p>
                                <h2>₹{item.price}</h2>
                                <p><EnvironmentOutlined /> S.G Highway, Ahmedabad</p>
                            </Card>
                        </Col>
                    ))
                ) : (
                    <p>No related products found.</p>
                )}
            </Row>
        </div>
    );
};

export default RelatedProduct;
