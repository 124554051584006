import React from 'react';
import { Layout, Menu, Button } from 'antd';
import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  YoutubeOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  CarOutlined,
  DollarOutlined,
  PictureOutlined,
  VideoCameraOutlined,
  TeamOutlined,
  SolutionOutlined,
  PhoneOutlined,
  LoginOutlined,
  WhatsAppOutlined
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import '../css/Header.css';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/authSlice';
import { setSelectedBrand } from '../redux/brandSlice';

const { Header } = Layout;

const AppHeader = () => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticate);
  const dispatch = useDispatch();
  const location = useLocation();

  // -----------------------------------------
  const handleSelectedBrand = () => {
    dispatch(setSelectedBrand());
  };
  // -----------------------------------------

  const logOUt = () => {
    dispatch(logout());
  };

  const menuItems = [
    {
      key: '/',
      icon: <HomeOutlined />,
      label: <Link to="/">Home</Link>,
    },
    {
      key: '/about',
      icon: <InfoCircleOutlined />,
      label: <Link to="/about">About</Link>,
    },
    {
      key: '/buycar',
      icon: <CarOutlined />,
      label: <Link onClick={handleSelectedBrand} to="/buycar">Buy Car</Link>,
    },
    {
      key: '/sellcar',
      icon: <DollarOutlined />,
      label: <Link to="/sellcar">Sell Car</Link>,
    },
    {
      key: '/gallery',
      icon: <PictureOutlined />,
      label: <Link to="/gallery">Gallery</Link>,
    },
    {
      key: '/videos',
      icon: <VideoCameraOutlined />,
      label: <Link to="/videos">Videos</Link>,
    },
    {
      key: '/ourteam',
      icon: <TeamOutlined />,
      label: <Link to="/ourteam">Our Team</Link>,
    },
    {
      key: '/career',
      icon: <SolutionOutlined />,
      label: <Link to="/career">Career</Link>,
    },
    {
      key: '/contact',
      icon: <PhoneOutlined />,
      label: <Link to="/contact">Contact</Link>,
    },
    isAuthenticated ?
    {
      key: 'Profile',
      icon: <LoginOutlined />,
      label: <Link>Profile</Link>,
      children: [
        {
          key: '/profile',
          label: <Link to="/profile">Profile</Link>,
        },
        {
          key: 'logout',
          label: <Link onClick={logOUt}>Log Out</Link>,
        }
      ],
    } :
    {
      key: 'Auth',
      icon: <LoginOutlined />,
      label: <Link>Login</Link>,
      children: [
        {
          key: '/login',
          label: <Link to="/login">Login</Link>,
        },
        {
          key: '/register',
          label: <Link to="/register">Register</Link>,
        }
      ],
    },
  ];

  // ---------------------------------------
  let selectedKey = location.pathname;
  if (location.pathname.startsWith('/product/')) {
    selectedKey = '/buycar';
  }
  // ----------------------------------------

  console.log("Current Path:", location.pathname); // Debugging log

  return (
    <Header className="header">
      <div className="header-top">
        <div className="logo">
          {/* ---------------------------------------------- */}
          <img src={`${process.env.PUBLIC_URL}/images/logo.png`} width={300} alt="logo" />
          {/* ---------------------------------------------- */}

        </div>
        <div className="right-side">
          <div className="social-icons">
            <FacebookOutlined />
            <InstagramOutlined />
            <LinkedinOutlined />
            <YoutubeOutlined />
            <WhatsAppOutlined />
          </div>
          <div className="action-buttons">
            <Button type="primary">Buy</Button>
            <Button type="primary">Sell</Button>
            <Button type="default" icon={<PhoneOutlined />}>9327647995</Button>
          </div>
        </div>
      </div>
      <div className="header-line"></div>
      {/* -------------------------------------------- */}
      <Menu
        mode="horizontal"
        className="menu"
        selectedKeys={[selectedKey]}
        items={menuItems}
      />
      {/* -------------------------------------------- */}

    </Header>
  );
};

export default AppHeader;
