export const years = [
    '2024',
    '2023',
    '2022',
    '2021',
    '2020',
    '2019',
    '2018',
    '2017',
    '2016',
    '2015',
    '2014',
    '2013',
    '2012',
    '2011',
    '2010',
    '2009',
    '2008',
    '2007',
    '2006',
    '2005',
    '2005 or Older',
];

export const models = [
    'Alto',
    'Alto K10',
    'A-Star',
    'Baleno',
    'Celerio',
    'Swift Dzire',
    'Celerio X',
    'Ciaz',
    'Eeco',
    'Esteem',
    'Wagon R',
    'Ertiga',
    'Estilo',
    'Grand Vitara',
    'Gypsy',
    'Kizashi',
    'Ignis',
    'Omni',
    'Ritz',
    'XL6',
    'S-Cross',
    'Stingray',
    'SX4',
    'Swift',
    'S-Presso',
    'Versa',
    'Vitara Brezza',
    'Fronx',
    'Invicto',
    'Jimny',
];

export const variant = [
    'LXi BS-III',
    'LX CNG',
    'LXi BS-IV',
    'LXi CNG',
    'Std BS-IV',
    'Std CNG',
    'XCITE',
    'LX (O)',
    'LXI',
    'LXI (O)',
    'LXI CNG (O)',
    'STD (O)',
    'VXI',
    'VXI (O)',
    'Lx (Airbag)',
    'Lxi (Airbag)',
    'LXi Anniversary Edition',
    'Lxi CNG (Airbag)',
    'Std (Airbag)',
    'Vxi (Airbag)',
    'LXi (O) CNG',
    'VXi Plus',
    'VXI 1.1',
    'TOUR H1',
]

export const kilometers = [
    '0 Km - 10,000 Km',
    '10,000 Km - 20,000 Km',
    '20,000 Km - 30,000 Km',
    '30,000 Km - 40,000 Km',
    '40,000 Km - 50,000 Km',
    '50,000 Km - 60,000 Km',
    '60,000 Km - 70,000 Km',
    '70,000 Km - 80,000 Km',
    '80,000 Km - 90,000 Km',
    '90,000 Km - 1,00,000 Km',
    '1,00,000 Km - 1,25,000 Km',
    '1,25,000 Km - 1,50,000 Km',
    '1,50,000 Km - 1,75,000 Km',
    '1,75,000 Km - 2,00,000 Km',
    '2,00,000 Km or more',
]