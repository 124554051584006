import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'antd';
import ChangePassword from './ChangePassword';

const Profile = () => {
    const API_BASE_URL = process.env.REACT_APP_API_URL;

    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);


    const selector = useSelector((state) => state.auth.loginData);

    useEffect(() => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (emailRegex.test(selector)) {
            setEmail(selector);
            setMobile('');
        } else {
            setMobile(selector);
            setEmail('');
        }
    }, [selector]);


    const API_URL = `${API_BASE_URL}/getCurrentUser.php`;

    const handleFetchUser = async () => {
        try {
            const params = {};
            if (email) params.email = email;
            if (mobile) params.mobile = mobile;

            const response = await axios.get(API_URL, { params });
            setUser(response.data.user);
            setError(null);
        } catch (err) {
            setUser(null);
            setError(err.response ? err.response.data.message : 'An error occurred');
        }
    };

    useEffect(() => {
        if (email || mobile) {
            handleFetchUser();
        }
    }, [email, mobile])

    console.log(user)

    return (
        <div>
            <h1>User Profile</h1>
            {user && (
                <div>
                    <h2>User Profile</h2>
                    <p>ID: {user.id}</p>
                    <p>Name: {user.name}</p>
                    <p>Email: {user.email}</p>
                    <p>Mobile: {user.mobile}</p>
                    <p>Address: {user.address}</p>

                    <Button type='primary' onClick={() => setIsAddModalOpen(true)}>Change Password</Button>
                    <Modal
                        title="Change Password"
                        open={isAddModalOpen}
                        onCancel={() => setIsAddModalOpen(false)}
                        footer={null}
                    >
                        <ChangePassword email={user.email} onClose={() => setIsAddModalOpen(false)} />
                    </Modal>
                </div>
            )}


            {error && (
                <div style={{ color: 'red' }}>
                    <p>Error: {error}</p>
                </div>
            )}
        </div>
    );
};

export default Profile;
