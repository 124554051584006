import React from 'react';
import { Row, Col, Card } from 'antd';

const features = [
    {
        imageSrc: './images/whychoose/lp-wnuc-1.png',
        title: 'Extensive Selection',
        description: 'We curate an extensive inventory of used cars from various makes and models, ensuring you have plenty of options to choose from. Our rigorous inspection process guarantees that each vehicle meets the highest standards of quality and performance.',
    },
    {
        imageSrc: './images/whychoose/lp-wnuc-2.png',
        title: 'Certified Quality',
        description: 'All our used cars undergo a thorough inspection by our team of experienced technicians. We offer certified pre-owned vehicles with warranty options, giving you the peace of mind you deserve when making your purchase.',
    },
    {
        imageSrc: './images/whychoose/lp-wnuc-3.png',
        title: 'Transparent History',
        description: 'At Jolly Motors, we believe in transparency. Each used car comes with a comprehensive vehicle history report, providing you with a detailed record of its past ownership, service history, and accident reports.',
    },
    {
        imageSrc: './images/whychoose/lp-wnuc-4.png',
        title: 'Financing Made Easy',
        description: 'Our finance experts are here to help you navigate the financing process smoothly. We work with reputable lenders to offer competitive financing options tailored to your needs and credit score.',
    },
    {
        imageSrc: './images/whychoose/lp-wnuc-5.png',
        title: 'Trade-In Assistance',
        description: 'Looking to upgrade from your current vehicle? Our trade-in assistance program ensures a fair evaluation of your trade-in, putting its value toward your new used car purchase.',
    },
    {
        imageSrc: './images/whychoose/lp-wnuc-6.png',
        title: 'Expert Advice',
        description: 'Our knowledgeable and friendly sales team is dedicated to assisting you in finding the perfect used car that suits your requirements and preferences. Were here to answer your questions and guide you through the entire buying process .',
    },
    {
        imageSrc: './images/whychoose/lp-wnuc-7.png',
        title: 'Customer Satisfaction',
        description: 'Your satisfaction is our top priority. From the moment you step into our showroom to the day you drive your used car home, we strive to provide exceptional customer service and support.',
    },
    {
        imageSrc: './images/whychoose/lp-wnuc-8.png',
        title: 'Explore Our Used Car Inventory',
        description: 'Our user-friendly website allows you to browse our up-to-date inventory online. You can search by make, model, price range, and other criteria, making it easy to find the used car that matches your needs. Each listing includes detailed specifications, photos, and pricing information to help you make an informed decision.',
    },
];

const WhyChooseSection = () => {
    return (
        <div style={{ padding: '30px' }}>
                <h2>Why Choose Jolly Motors for Your Next Used Car?</h2>
            <Row gutter={[16, 16]}>
                {features.map((feature, index) => (
                    <Col xs={24} sm={24} md={8} key={index}>
                        <Card bordered={true} style={{ textAlign: 'center', height: '100%' }}>
                            <div>
                                <img
                                    src={feature.imageSrc}
                                    alt={feature.title}
                                    style={{ width: '50px', height: '50px' }}
                                />
                            </div>
                            <h5 style={{ marginTop: '15px', marginBottom: '10px' }}>{feature.title}</h5>
                            <p>{feature.description}</p>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default WhyChooseSection;
