import React from 'react';
import { Layout } from 'antd';
import AppHeader from '../components/Header';

const { Content } = Layout;

const LayoutComponent = ({ children }) => {
    return (
        <Layout style={{ minHeight: '100vh', maxWidth : '1440px', margin: 'auto' }}>
            <AppHeader />
            <Layout>
                <Content style={{ overflow: 'initial', background: '#fff' }}>
                    <div style={{ padding: 24 }}>
                        {children}
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default LayoutComponent;
